import AppContext from "@src/context"
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from "prop-types"
import 'plyr/dist/plyr.css';
import Loadable from '@loadable/component';
import { isMobile, debounce } from "@utils/helper"
import './styles.scss';
const VideoPlayer = Loadable(() => import("@components/content/VideoPlayer.jsx"));

const VideoChapter = ({ id, chapter, transcriptUrl, children }) => {
	const ctx = useContext(AppContext)
	const [isPlaying, setPlaying] = useState(false);
	const [iconSize, setIconSize] = useState(null);

	useEffect(() => {
		if (ctx.videoEls && Object.keys(ctx.videoEls).length) {
			const videoObjKey = "id-" + id + "-" + chapter;

			if (videoObjKey in ctx.videoEls) {
				if (isPlaying) {
					ctx.videoEls[videoObjKey].currentTime = chapter;
					ctx.videoEls[videoObjKey].volume = 1;
					setTimeout(() => {
						ctx.videoEls[videoObjKey].play()
					}, 1500);
				} else {
					ctx.videoEls[videoObjKey].pause()
				}
			}
		}
	}, [isPlaying])

	const handleResizeIcons = () => {
		if (isMobile()) {
			setIconSize(44);
		} else {
			setIconSize(62);
		}
	}

	useEffect(() => {
		handleResizeIcons();
		const resizeListener = debounce(handleResizeIcons, 5);
		window.addEventListener("resize", resizeListener);

		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, [])

	return (
		<div className='video-chapter--container'>
			<div role="button" tabIndex="0" className='video-chapter-btn'
				onKeyDown={(e) => { if (e.keyCode === 32 || e.keyCode === 13) setPlaying(!isPlaying) }}
				onClick={() => setPlaying(!isPlaying)}
			>
				<div className='video-chapter-btn--top'>
					{!isPlaying ?
						<div>
							<img src="/images/video-chapter/icon-video-chapter.svg" width={iconSize} height={iconSize} alt="" className="top-btn-play" />
							<img src="/images/video-chapter/icon-video-chapter-hover.svg" width={iconSize} height={iconSize} alt="" className="top-btn-play--hover" />
						</div>
						:
						<div>
							<img src="/images/video-chapter/icon-video-chapter-pause.svg" width={iconSize} height={iconSize} alt="" className="top-btn-pause" />
							<img src="/images/video-chapter/icon-video-chapter-pause-hover.svg" width={iconSize} height={iconSize} alt="" className="top-btn-pause--hover" />
						</div>
					}
					<p>Want help understanding the data below?<br /> Watch this video explanation from an actual doctor.</p>
				</div>

				<div className={`absolute w-[calc(100%-20px)] left-2.5 bottom-2.5 ${isPlaying ? "hidden" : "inline-block"}`}>
					{children}
				</div>
			</div>

			<div className="video-chapter-btn--shape-cover"></div>
			<div className={`absolute w-[calc(100%-20px)] left-2.5 bottom-2.5 ${isPlaying ? "inline-block" : "hidden"}`}>
				<VideoPlayer id={id} chapterVideo={chapter} />
			</div>
			<a href={transcriptUrl} target='_blank' rel='noreferrer' className='link--orange gtm-transcript-cta'>View Video Transcript</a>
		</div>
	)
}

export default VideoChapter

VideoChapter.defaultProps = {
	chapter: 0,
	children: null,
}

VideoChapter.propTypes = {
	id: PropTypes.number.isRequired,
	chapter: PropTypes.number,
	transcriptUrl: PropTypes.string.isRequired,
	children: PropTypes.any,
}
