import React, { useContext, useEffect } from 'react';
import Loadable from '@loadable/component';
import { KfAccordion, KfAccordionItem, KfButton, KfHeroUtility, isMobile } from '@klickinc/kf-react-components';
import AppContext from "@src/context";
// Utility imports
import Image from '@components/utility/Image';
import Seo from '@components/utility/SEO';
import { graphql } from 'gatsby';
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import GTM from '@utils/GTM';
import { handleFBQ } from '@utils/FBQ';
import './styles.scss';

// Content imports
import VideoChapter from '@components/page-sections/about-emflaza/VideoChapter';
import BottomCta from '@components/content/BottomCta';

const VideoPlayer = Loadable(() => import("@components/content/VideoPlayer.jsx"));

const AboutEmflaza = ({ data }) => {
	const ctx = useContext(AppContext);

	const image_imageChapter1 = getImage(data.imageChapter1);
	const image_imageChapter2 = getImage(data.imageChapter2);
	const image_imageChapter3 = getImage(data.imageChapter3);
	const image_imageChapter4 = getImage(data.imageChapter4);
	const image_acc2016 = getImage(data.acc2016);
	const image_accentGraphic = getImage(data.accentGraphic);
	const image_acc2018 = getImage(data.acc2018);
	const image_ctaSparkles = getImage(data.ctaSparkles);
	const image_acc2015 = getImage(data.acc2015);
	const image_acc2020 = getImage(data.acc2020);
	const image_bgBottom = withArtDirection(getImage(data.bgBottom), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.bgBottomM),
		},
	]);

	const closeAccordion = (id) => {
		const el = document.querySelector(`#${id}`);

		if (el) {
			const trigger = el.querySelector('.kf-accordion__trigger button');
			if (trigger) trigger.click();
		}
	};

	const toggleBgHeight = (e) => {
		const accState = e.target.getAttribute('aria-expanded') === 'true';
		const background = document.querySelector('.bg--orange');
		if (accState) {
			if (background) background.classList.add('expanded-acc');
		} else if (!accState) {
			if (background) background.classList.remove('expanded-acc');
		}
	}

	const handleToggle = (e) => {
		const trigger = e.target;
		const expanded = trigger.getAttribute('aria-expanded') === 'true';
		const parent = trigger.closest('.kf-accordion');

		if (parent) {
			const id = parent.id.replace(/-1-|-2-|-3-/g, ' ').replace('-', ' ');
			const label = `See ${id}`;
			GTM.accordionToggle(expanded, label);

			if (id === 'study summary 2020' && !isMobile()) {
				const pt = document.querySelector('.section-challenging');
				if (expanded) {
					pt.classList.add('pt-6');
				} else {
					pt.classList.remove('pt-6');
				}
			}
		}

		if (expanded) {
			trigger.scrollIntoView();
		}
	};

	useEffect(() => {
		ctx.setPageClass('page--about-emflaza');

		// bg height adjustment
		const accBtn = document.querySelector('#study-summary-2-2018 .kf-accordion__trigger button');
		if (accBtn) accBtn.addEventListener('click', (e) => toggleBgHeight(e));

		const triggers = document.querySelectorAll('.kf-accordion__trigger button');
		triggers.forEach(trigger => {
			trigger.addEventListener('click', (e) => {
				handleToggle(e, trigger)

				const expanding = e.target.getAttribute('aria-expanded') === 'false';
				if (expanding) {
					handleFBQ('Search');
				}
			});
		});

		return () => {
			if (accBtn) accBtn.removeEventListener('click', () => toggleBgHeight);
			triggers.forEach(trigger => {
				trigger.removeEventListener('click', (e) => {
					handleToggle(e, trigger)
				});
			});
		}
	}, [])

	return (
		<>
			<section className='relative'>
				<div className="container container--inner">
					<div className="section-help-boys">
						<KfHeroUtility addedClass='heading heading-hero-blue'>
							Help boys with DMD preserve their<br className='hidden xl:block' /> "here and now"
						</KfHeroUtility>

						<h2 className='max-w-2xl relative z-10 orange-heading'>As <span className='uppercase'>the first</span> FDA&#8209;approved corticosteroid for boys with DMD as young as age 2, EMFLAZA is designed to help maintain muscle strength and function.</h2>

						<VideoChapter
							id={839862843}
							transcriptUrl="/video-transcript/efficacy-deep-dive#part1"
						>
							<Image
								imageData={image_imageChapter1}
								alt=""
							/>
							<p className='sr-only'>Actual EMFLAZA patient.</p>
						</VideoChapter>

						<div className='accordion'>
							<Image
								imageData={image_acc2016}
								alt="2016 study"
							/>
							<div className='acc-inner'>
								<p>In phase 1 (from baseline to 12 weeks) of a 52&#8209;week clinical trial with 196 boys aged 5 to 15 years with DMD, the effectiveness and safety of EMFLAZA were compared to placebo (sugar pill). After 12 weeks of treatment, patients taking EMFLAZA had significantly improved muscle strength compared with placebo (0.15 change in strength score vs -0.10 change in strength score).</p>
								<KfAccordion addedId='study-summary-1-2016'>
									<div>
										<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -">
											<h4>Griggs 2016 Study Summary</h4>
											<h5>Efficacy and safety of deflazacort vs prednisone and placebo for Duchenne muscular dystrophy.</h5>
											<p><span>Objective:</span> To assess safety and efficacy of deflazacort (DFZ) and prednisone (PRED) vs placebo in DMD.</p>
											<p><span>Methods:</span> This phase III, double-blind, randomized, placebo-controlled, multicenter study evaluated muscle strength among 196 boys aged 5&nbsp;&#8209;&nbsp;15 years with DMD during a 52&#8209;week period. The study was completed in 1995.</p>
											<p><span>Results:</span> After 12 weeks of treatment, PRED and both doses of DFZ improved muscle strength compared with placebo. DFZ was associated with less weight gain than PRED.</p>
											<KfButton clickFn={() => closeAccordion('study-summary-1-2016')}>Close Study Summary -</KfButton>
										</KfAccordionItem>
									</div>
								</KfAccordion>
							</div>
						</div>
						<div className='accent-graphic w-full flex justify-end sm:hidden mb-8'>
							<Image
								imageData={image_accentGraphic}
								alt=""
							/>
						</div>
						<h3 className='heading-bg lg:ml-20'>Make the Most <br className='block md:hidden' /> of His Now</h3>
					</div>

				</div>
			</section>
			<section className="relative" id='long-term-data'>
				<div className="container container--inner">

					<div className='section-help-delay-content'>
						<h2 className="heading heading-hero-blue">Help delay dmd progression,<br /> When it matters </h2>

						<p className='text-sm'>In a 10-year follow-up study measuring 3 important DMD events, <span className='font-bold'>deflazacort showed differences over time compared to prednisone</span> by helping boys maintain:</p>

						<VideoChapter
							id={839862843}
							chapter={82}
							transcriptUrl="/video-transcript/efficacy-deep-dive#part2"
						>
							<Image
								imageData={image_imageChapter2}
								alt=""
							/>
						</VideoChapter>

						<div className='accordion'>
							<Image
								imageData={image_acc2018}
								alt="2018 study"
							/>
							<div className='acc-inner'>
								<p>In a study of 440 boys aged 2 to 28 years with DMD, the effectiveness and safety of deflazacort were compared with prednisone.</p>
								<KfAccordion addedId='study-summary-2-2018'>
									<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -">
										<h4>Mcdonald 2018 study summary</h4>
										<h5>Long-term effects of glucocorticoids on function, quality of life, and survival in patients with Duchenne muscular dystrophy: a prospective cohort study.</h5>
										<p><span>Objective:</span> This study examined the long&#8209;term effects of glucocorticoids on milestone&#8209;related disease progression across the lifespan and survival in patients with DMD.</p>


										<p><span>Methods:</span> For this prospective cohort study, male patients aged 2&nbsp;&#8209;&nbsp;28 years with DMD were enrolled at 20 centers in nine countries. Patients were followed up for 10 years. The study measured the progression of nine mobility and upper limb milestones to compare no glucocorticoid treatment or cumulative treatment duration of less than 1&nbsp;month versus treatment of 1 year or longer.</p>
										<p><span>Results:</span> 440 patients were enrolled during two recruitment periods (2006&nbsp;&#8209;&nbsp;09 and 2012&nbsp;-&nbsp;16). Time to all disease progression milestone events was significantly longer in patients treated with glucocorticoids for 1&nbsp;year or longer than in patients treated for less than 1&nbsp;month or never treated (log-rank). Glucocorticoid treatment for 1 year or longer was associated with increased median age at loss of mobility milestones by 2.1&nbsp;&#8209;&nbsp;4.4 years and upper limb milestones by 2.8&nbsp;&#8209;&nbsp;8.0 years compared with treatment for less than 1&nbsp;month. Deflazacort was associated with increased median age at loss of three milestones by 2.1&nbsp;&#8209;&nbsp;2.7 years in comparison with prednisone or prednisolone (log-rank). </p>

										<p><span className='uppercase'>Please note</span><br />This study is not in the approved prescribing label for EMFLAZA, but is consistent with the information that is included. Please talk to your son's healthcare provider if you have any questions.</p>
										<KfButton clickFn={() => closeAccordion('study-summary-2-2018')}>Close Study Summary -</KfButton>
									</KfAccordionItem>
								</KfAccordion>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='ambulation-outcomes relative py-2'>
				<div className="container container--inner relative">
					<div className='sparkles'>
						<Image
							imageData={image_ctaSparkles}
							alt=''
							loading='eager'
						/>
					</div>
					<h2 className="heading-hero-blue">
						<span className='heading'>Age at loss of ambulation with deflazacort</span> <strong>vs</strong> <span className='heading'>prednisone</span>
					</h2>

					<VideoChapter
						id={839862843}
						chapter={224}
						transcriptUrl="/video-transcript/efficacy-deep-dive#part3"
					>
						<Image
							imageData={image_imageChapter3}
							alt=""
						/>
					</VideoChapter>

					<div className='study-information'>
						<Image
							imageData={image_acc2015}
							alt="2015 Study"
						/>
						<div className='study-information--inner'>
							<div className='study-information--inner-copy'>
								<div className='study-information--inner-copy-inner'>
									<h4>Bello 2015 Study Summary</h4>
									<h5>Prednisone/prednisolone and deflazacort regimens in CINRG Duchenne Natural History Study</h5>
									<p><span>Objective:</span> This study observed the age at loss of independent ambulation (LoA) and side-effect profiles associated with different glucocorticoid corticosteroid (GC) regimens in DMD.</p>
									<p><span>Methods:</span> This observational study assessed 340 participants aged 2 to 28 years in the Cooperative International Neuromuscular Research Group Duchenne Natural History Study (CINRG-DNHS). LoA was defined as continuous wheelchair use. Effects of prednisone or prednisolone/deflazacort, administration frequency, and dose were analyzed by time-varying Cox regression (a method to estimate survival time). Side-effect frequencies were compared using x2 test. </p>
									<p><span>Results:</span> Participants treated 1 year or longer while ambulatory showed a 3-year median delay in LoA. Fourteen different regimens were observed. Non-daily treatment was common for prednisone (37%) and rare for deflazacort (3%). Deflazacort was associated with later LoA than prednisone. Average dose was lower for daily prednisone (75% of the recommended dose) than daily deflazacort (83% of the recommended dose). Deflazacort showed higher frequencies of growth delay, cushingoid appearance, and cataracts, but not weight gain.</p>
									<p><span className='uppercase'>Please note</span><br />This study is not in the approved prescribing label for EMFLAZA, but is consistent with the information that is included. Please talk to your son's healthcare provider if you have any questions.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='relative'>
				<div className="container container--inner">
					<div className="section-help-support">
						<h2 className="pt-8 heading heading-hero-blue md:pt-12">Help support him <br />as he grows</h2>
						<p>Once your son starts taking deflazacort, it's important for him to stay on treatment as it was shown to <span className='font-bold'>preserve muscle function in more places than just his arms and legs.</span>
						</p>

						<h3 className='orange-heading mt-7'>In a 13-year study, scoliosis was delayed and lung function was preserved for longer in boys taking deflazacort.</h3>

						<div className='w-full h-px mt-4 bg-emflaza-blue-600'></div>

						<p className='mt-4'>A 13-year study of 435 boys with DMD assessed patients' ages simultaneously with DMD progression events, including scoliosis onset and decrease in lung function.</p>

						<VideoChapter
							id={839862843}
							chapter={307}
							transcriptUrl="/video-transcript/efficacy-deep-dive#part4"
						>
							<Image
								imageData={image_imageChapter4}
								alt=""
							/>
						</VideoChapter>

						<div className='accordion'>
							<Image
								imageData={image_acc2020}
								alt="2020 study"
							/>

							<div className='acc-inner'>
								<KfAccordion addedId='study-summary-3-2020'>
									<KfAccordionItem expandLabel="See Study Summary +" collapseLabel="Close Study Summary -">
										<h4>Marden 2020 study summary</h4>
										<h5>Real-world outcomes of long-term prednisone and deflazacort use in patients with Duchenne muscular dystrophy: experience at a single, large care center.</h5>
										<p><span>Objective:</span> To assess outcomes among patients with DMD receiving deflazacort or prednisone in real-world practice.</p>
										<p><span>Methods:</span> Clinical data for 435 boys with DMD from Cincinnati Children's Hospital Medical Center were studied retrospectively using time-to-event and regression analyses.</p>
										<p><span>Results:</span> Median ages at loss of ambulation were 15.6 and 13.5 years among deflazacort- and prednisone&#8209;initiated patients, respectively. Deflazacort was also associated with a lower risk of scoliosis, improved ambulatory function, greater % lean body mass, shorter stature, and lower weight, after adjusting for age and steroid duration. No differences were observed in whole body bone mineral density or left ventricular ejection fraction.</p>

										<p><span className='uppercase'>Please note</span><br />This study is not in the approved prescribing label for EMFLAZA, but is consistent with the information that is included. Please talk to your son's healthcare provider if you have any questions.</p>
										<KfButton clickFn={() => closeAccordion('study-summary-3-2020')}>Close Study Summary -</KfButton>
									</KfAccordionItem>
								</KfAccordion>
							</div>
						</div>

					</div>
				</div>
			</section>

			<section className="relative bg--blue">
				<div id='kasners' className="container container--inner section-challenging">
					<h2 className="pt-8 heading heading-hero-blue md:pt-12">Here & now with <br />the Kasners</h2>
					<p className='mb-10'>Sarah and Dan work together to care for their two sons with DMD. Watch how they try to have fun and enjoy life as much as they can.</p>

					<div className='video'>
						<VideoPlayer id={778158200} />
						<a href='/video-transcript/here-now-kasners' target='_blank' rel='noreferrer' className='gtm-transcript-cta transcript-cta'>View Video Transcript</a>
					</div>

					<BottomCta
						ctaUrl="/safety"
						image={data}
					>
						Emflaza Safety Profile
					</BottomCta>
				</div>
				<div className='bg-2'>
					<Image
						imageData={image_bgBottom}
						alt=''
						loading='eager'
					/>
				</div>
			</section>
		</>
	);
};

export default AboutEmflaza;


export const pageQuery = graphql`
  query {
	imageChapter1: file(relativePath: {eq: "video-chapter/video-chapter-1.jpg"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	imageChapter2: file(relativePath: {eq: "video-chapter/video-chapter-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	imageChapter3: file(relativePath: {eq: "video-chapter/video-chapter-3.jpg"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	imageChapter4: file(relativePath: {eq: "video-chapter/video-chapter-4.jpg"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	acc2016: file(relativePath: {eq: "icons/acc-icon-2016.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	accentGraphic: file(relativePath: {eq: "pages/about-emflaza/accent-graphic.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	acc2018: file(relativePath: {eq: "icons/acc-icon-2018.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	ctaSparkles: file(relativePath: {eq: "pages/getting-started/cta-sparkles.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	acc2015: file(relativePath: {eq: "pages/about-emflaza/2015-badge.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	acc2020: file(relativePath: {eq: "icons/acc-icon-2020.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bgBottom: file(relativePath: {eq: "pages/about-emflaza/2-0-bg-bottom.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bgBottomM: file(relativePath: {eq: "pages/about-emflaza/2-0-bg-bottom-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImage: file(relativePath: {eq: "pages/about-emflaza/bottom-cta/about-cta-image-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImageMobile: file(relativePath: {eq: "pages/about-emflaza/bottom-cta/about-cta-image-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage: file(relativePath: {eq: "pages/about-emflaza/bottom-cta/about-cta-image-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImage: file(relativePath: {eq: "pages/about-emflaza/bottom-cta/about-cta-image-3.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImageMobile: file(relativePath: {eq: "pages/about-emflaza/bottom-cta/about-cta-image-3-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
	return (
		<Seo
			title='What Is EMFLAZA® (deflazacort)?'
			description='EMFLAZA® (deflazacort) is an FDA-approved corticosteroid for boys with DMD as young as age 2. See data on the effectiveness and safety of EMFLAZA and view full Prescribing Information & Important Safety Information.'
		>
			<script type="application/ld+json">
				{JSON.stringify({
					"@context": "http://schema.org",
					"type": "VideoObject",
					"name": "Here & Now With the Kasners: A DMD Story",
					"description": "Sarah and Dan work together to care for their two sons with DMD. Watch how they try to have fun and enjoy life as much as they can.",
					"thumbnailUrl": "https://i.vimeocdn.com/video/1495638772-25769eb9855a816959fbbd261c4e9b764ed6effdec4c920704f109965f99585b-d",
					"uploadDate": "2022-09-30",
					"embedUrl": "https://www.emflaza.com/about-emflaza",
					"contentUrl": "https://player.vimeo.com/video/740846411",
					"duration": "PT5M54S"
				})}
			</script>
			<script type="application/ld+json">
				{JSON.stringify({
					"@context": "http://schema.org",
					"type": "VideoObject",
					"name": "The Science Behind EMFLAZA® (deflazacort)",
					"description": "Watch as DMD specialists, Dr. Saunders M. Bernes and Dr. Michelle Figueroa discuss the science behind EMFLAZA and its impact on muscle strength, disease progression, and loss of ambulation. ",
					"thumbnailUrl": "https://i.vimeocdn.com/video/1689587785-a7b215ec8b8622de459ad4193b3845b986ab5a75a5bebec38e9f09f35d16fc7f-d",
					"uploadDate": "2023-07-10",
					"embedUrl": "https://www.emflaza.com/about-emflaza",
					"contentUrl": "https://player.vimeo.com/video/839862843",
					"duration": "PT10M14S"
				})}
			</script>
		</Seo>
	);
};
